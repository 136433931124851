<template>
  <div style="padding: 20px">
        <div>
          <div class="handle-box">
            <el-button size="small" type="primary" class="handle-del mr10" @click="addVisible = true">新增卡片</el-button>
            <el-select size="small" placeholder="学员类型" style="width: 120px;margin-right: 10px" v-model="query.studentType">
              <div v-if="sysStudentType==3">
                <el-option label="全部"></el-option>
                <el-option label="中招体育" value="1"></el-option>
                <el-option label="少年" value="2"></el-option>
                <el-option label="高考体育" value="4"></el-option>
              </div>
              <div v-if="sysStudentType!=3">
                <el-option v-if="sysStudentType==1" label="中招体育" value="1"></el-option>
                <el-option v-else label="少年" value="2"></el-option>
                <el-option label="高考体育" value="4"></el-option>
              </div>
            </el-select>
              <el-select style="width: 150px" size="small" v-model="query.regionId" placeholder="城市" class="handle-select mr10">
                  <el-option @click="selectCity1(index)"
                             v-for="(item,index) in regionList" :key="index"
                             :label="item.name"
                             :value="item.id"></el-option>
              </el-select>
              <el-select style="width: 150px" size="small" v-model="query.typeId" placeholder="卡片类型" class="handle-select mr10">
                  <el-option  v-for="(item,index) in cardTypeList" :key="index"
                             :label="item.dictLabel" :value="item.dictValue"></el-option>
              </el-select>
              <el-input style="width: 150px" size="small"  v-model="query.keyword" placeholder="卡片名称" class="handle-input mr10"></el-input>
            <el-select style="width: 150px" size="small" v-model="query.state" placeholder="卡片状态" class="handle-select mr10">
              <el-option label="全部" value=""></el-option>
              <el-option label="开启" value="开启"></el-option>
              <el-option label="禁用" value="禁用"></el-option>
            </el-select>
              <el-button size="small" type="primary" icon="Search" @click="handleSearch">搜索</el-button>
              <el-button size="small" type="info" icon="Search" @click="reset">重置</el-button>
              <el-popconfirm @confirm="exportExcel"
                             title="确定要导出吗？"
              >
                  <template #reference>
                      <el-button type="primary" size="small" icon="Download">导出</el-button>
                  </template>
              </el-popconfirm>
          </div>
          <el-table
                  :data="tableData"
                  border
                  class="table"
                  ref="multipleTable"
                  header-cell-class-name="table-header"
                  :row-style="{height:0+'px'}"
                  :cell-style="{padding:1+'px'}"
          >
              <el-table-column type="expand">
                  <template #default="scope">
                      <el-table
                              :data="scope.row.specificationsList"
                              border
                              class="table"
                              ref="multipleTable"
                              header-cell-class-name="table-header"
                              :row-style="{height:10+'px'}"
                              :cell-style="{padding:6+'px'}"
                      >
                          <el-table-column type="expand">
                              <template #default="scope">
                                  <div style="display: flex;padding-right: 20px">

                                      <el-table
                                              :data="scope.row.sendOrder"
                                              border
                                              class="table"
                                              ref="multipleTable"
                                              header-cell-class-name="table-header"
                                              :row-style="{height:0+'px'}"
                                              :cell-style="{padding:0+'px'}"
                                      >
                                          <el-table-column type="index" label="序号" width="50"
                                                           align="center"></el-table-column>
                                          <el-table-column prop="name" label="赠送卡片名称" width="110"
                                                           show-overflow-tooltip="true"
                                                           align="center"></el-table-column>
                                          <el-table-column prop="price" label="价格" width="100"
                                                           align="center"></el-table-column>
                                          <el-table-column prop="num" label="数量" width="50"
                                                           align="center"></el-table-column>
                                          <el-table-column
                                                  label="状态"
                                                  width="80">
                                              <template #default="scope">
                                                  <el-tooltip
                                                          :content="scope.row.state==='开启'? '关闭后此用户将无法使用':'确定要开启吗'"
                                                          placement="top">
                                                      <el-switch
                                                              @click="setChatSendGoodsState(scope.row.id,scope.row.state)"
                                                              v-model="scope.row.state"
                                                              active-value="开启"
                                                              inactive-value="关闭"
                                                      >
                                                      </el-switch>
                                                  </el-tooltip>
                                              </template>
                                          </el-table-column>
                                      </el-table>
                                      <el-table
                                              :data="scope.row.sendEquipment"
                                              border
                                              class="table"
                                              ref="multipleTable"
                                              header-cell-class-name="table-header"
                                              :row-style="{height:0+'px'}"
                                              :cell-style="{padding:0+'px'}"
                                      >
                                          <el-table-column type="index" label="序号" width="50"
                                                           align="center"></el-table-column>
                                          <el-table-column prop="name" label="赠送器材名称" width="110"
                                                           show-overflow-tooltip="true"
                                                           align="center"></el-table-column>
                                          <el-table-column prop="price" label="价格" width="100"
                                                           align="center"></el-table-column>
                                          <el-table-column prop="num" label="数量" width="50"
                                                           align="center"></el-table-column>
                                          <el-table-column
                                                  label="状态"
                                                  width="80">
                                              <template #default="scope">
                                                  <el-tooltip
                                                          :content="scope.row.state==='开启'? '关闭后此用户将无法使用':'确定要开启吗'"
                                                          placement="top">
                                                      <el-switch
                                                              @click="setChatSendGoodsState(scope.row.id,scope.row.state)"
                                                              v-model="scope.row.state"
                                                              active-value="开启"
                                                              inactive-value="关闭"
                                                      >
                                                      </el-switch>
                                                  </el-tooltip>
                                              </template>
                                          </el-table-column>
                                      </el-table>
                                  </div>
                              </template>


                          </el-table-column>
                          <el-table-column type="index" label="序号" width="50"
                                           align="center"></el-table-column>
                          <el-table-column prop="actualPrice" label="实际价格" width="100"
                                           align="center"></el-table-column>
                          <el-table-column prop="promotionPrice" label="促销价格" width="100"
                                           align="center"></el-table-column>
                          <el-table-column
                                  label="状态"
                                  width="80">
                              <template #default="scope">
                                  <el-tooltip :content="scope.row.state==='开启'? '关闭后此用户将无法使用':'确定要开启吗'"
                                              placement="top">
                                      <el-switch
                                              @click="setSpeState(scope.row.id,scope.row.state)"
                                              v-model="scope.row.state"
                                              active-value="开启"
                                              inactive-value="关闭"
                                      >
                                      </el-switch>
                                  </el-tooltip>
                              </template>
                          </el-table-column>
                          <el-table-column prop="purchaseLimit" label="单人限购数量" width="110"
                                           align="center"></el-table-column>
                          <el-table-column prop="specificationsName" label="规格名称" width="200"
                                           show-overflow-tooltip="true"
                                           align="center"></el-table-column>
                          <el-table-column prop="createTime" label="创建时间" width="180"
                                           align="center"></el-table-column>
                          <el-table-column label="操作" width="250" align="center">
                              <template #default="scope">
                                  <el-button
                                          type="text"
                                          icon="el-icon-edit"
                                          @click="updateCardSpe(scope.$index,scope.row)"
                                  >编辑
                                  </el-button>
                                  <el-button type="text" icon="el-icon-edit"
                                             @click="addChatSendGoods(scope.$index, scope.row)">新增赠送
                                  </el-button>
                              </template>

                          </el-table-column>
                      </el-table>

                  </template>
              </el-table-column>
              <!--                <el-table-column type="selection" width="55" align="center"><template #default="props">{{props}}</template></el-table-column>-->
              <el-table-column type="selection" width="55" align="center"></el-table-column>
              <el-table-column type="index" label="序号" width="55" align="center"></el-table-column>
              <el-table-column prop="cardname" label="卡种名称" show-overflow-tooltip="true" width="150"></el-table-column>
              <!--        <el-table-column prop="num" label="库存"></el-table-column>-->
              <el-table-column prop="regionName" label="所属城市" width="100"></el-table-column>
              <el-table-column prop="typeName" label="课程类型" width="100"></el-table-column>
            <el-table-column label="课程类型" width="110">
              <template #default="scope"> <el-tag>{{scope.row.flagName}}</el-tag></template>
            </el-table-column>
              <el-table-column prop="price" label="价格" width="100"></el-table-column>
              <el-table-column prop="eliminateTypeName" label="卡种类型" width="100"></el-table-column>
              <!--        <el-table-column label="所属校区">
                        <template #default="scope">{{ scope.row.campus != null? scope.row.campus.campusname:'' }}</template>
                      </el-table-column>-->
              <el-table-column prop="effectiveTime" label="期限天数(天)" width="110"></el-table-column>
              <el-table-column width="110" align="center" label="缩略图">
              <template v-slot="scope">
                <el-image class="code"
                          :src="scope.row.thumbnail">
                  <template #error>
                    <div class="code">
                    </div>
                  </template>
                </el-image>
              </template>
            </el-table-column>
              <el-table-column prop="explain" label="收据备注" show-overflow-tooltip="true"></el-table-column>
              <el-table-column prop="startdate" label="开始时间" show-overflow-tooltip="true" width="150"> </el-table-column>
              <el-table-column prop="enddate" label="结束时间" show-overflow-tooltip="true" width="150"></el-table-column>
              <!--        <el-table-column prop="updateTime" label="修改时间"></el-table-column>-->

              <el-table-column
                      label="状态"
                      width="110">
                  <template #default="scope">
                      <el-tooltip :content="scope.row.state=='开启'? '关闭后此卡种将无法使用':'确定要开启吗'" placement="top">
                          <el-switch
                                  @click="setState(scope.row.id,scope.row.state)"
                                  v-model="scope.row.state"
                                  active-value="开启"
                                  inactive-value="关闭"
                          >
                          </el-switch>
                      </el-tooltip>
                  </template>
              </el-table-column>


              <el-table-column label="操作" width="180" align="center">
                  <template #default="scope">
                      <el-button
                              type="text"
                              icon="el-icon-edit"
                              @click="handleEdit(scope.$index, scope.row)"
                      >编辑
                      </el-button>
                      <el-button :disabled="scope.row.role==0||scope.row.role==2?false:true"
                                 type="text"
                                 icon="el-icon-edit"
                                 @click="addCareSpe(scope.$index, scope.row)"
                      >新增规格
                      </el-button>
                  </template>
              </el-table-column>
          </el-table>
          <div class="pagination">
              <el-pagination
                      background
                      layout="total, prev, pager, next"
                      :current-page="query.pageIndex"
                      :page-size="query.pageSize"
                      :total="pageTotal"
                      @current-change="handlePageChange"
              ></el-pagination>
          </div>
        </div>
        <add-card-and-edit-card  @updateDialog="updateDialog()" :addVisible="addVisible"></add-card-and-edit-card>
        <!-- 编辑弹出框 -->
        <el-dialog title="修改卡种" v-model="editVisible" width="1000px">
            <el-form :rules="rules" ref="ruleForm" :model="form" label-width="120px">
                <div style="display: flex;padding-right: 20px">
                    <el-form-item label="卡种名称" prop="cardname">
                        <el-input class="handle-select" v-model="form.cardname"></el-input>
                    </el-form-item>

                    <!--      <el-form-item label="所属城市" prop="regionId">
                            <el-select v-model="form.regionid" placeholder="城市" class="handle-select">
                              <el-option @click="selectCity2(index)" v-for="(item,index) in regionList" :key="index"
                                         :label="item.region"
                                         :value="item.id"></el-option>
                            </el-select>
                          </el-form-item>-->

                    <!--        <el-form-item label="所属校区" prop="campusid">
                              <el-select v-model="form.campusid" placeholder="校区" class="handle-select">
                                <el-option v-for="(item,index) in campusList1" :key="index" :label="item.campusname"
                                           :value="item.id"></el-option>
                              </el-select>
                            </el-form-item>-->
                </div>
                <div style="display: flex;padding-right: 20px">
                    <el-form-item label="消课方式" prop="eliminateTypeId">
                        <el-select v-model="form.eliminateTypeName" placeholder="消课方式" class="handle-select"
                                   disabled="true">
                            <el-option v-for="(item,index) in elList" :key="index" :label="item.name"
                                       :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="有效日期" prop="effectiveTime">
                        <el-input class="handle-select" placeholder="有效天数" v-model="form.effectiveTime"></el-input>
                    </el-form-item>
                    <el-form-item label="可见权限" prop="role">
                        <el-select v-model="form.role" placeholder="谁可以看" class="handle-select">
                            <el-option label="全部可见" :value="0"></el-option>
                            <el-option label="教练可见" :value="1"></el-option>
                            <el-option label="家长可见" :value="2"></el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div style="display: flex;">
                    <el-form-item label="开始日期" prop="startdate">
                        <el-date-picker
                            style="width: 180px"
                            format="YYYY-MM-DD"
                            value-format="YYYY-MM-DD"
                                        v-model="form.startdate" type="date" placeholder="开始日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="结束日期" prop="enddate">
                        <el-date-picker
                            style="width: 180px"
                            format="YYYY-MM-DD"
                            value-format="YYYY-MM-DD"
                                        v-model="form.enddate" type="date" placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="排序" prop="sort">
                        <el-input style="width: 180px" type="number" v-model="form.sort"></el-input>
                    </el-form-item>
                </div>
                <div style="display: flex;">
                    <el-form-item label="上架时间" prop="secKillTime">
                        <el-date-picker style="width: 200px"
                                        type="datetime"
                                        v-model="form.secKillTime">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="下价时间" prop="soldEndTime">
                        <el-date-picker style="width: 200px"
                                        type="datetime"
                                        v-model="form.soldEndTime">
                        </el-date-picker>
                    </el-form-item>

                </div>

                <div style="display: flex;">
                    <div>
                        <el-form-item label="商品描述" prop="describe">
                            <el-input :rows="3" style="width: 400px" type="textarea" v-model="form.describe"></el-input>
                        </el-form-item>
                        <el-form-item label="收据备注" prop="explain">
                            <el-input :rows="2" type="textarea" v-model="form.explain"></el-input>
                        </el-form-item>
                    </div>
                    <el-form-item label="缩略图">
                        <el-upload
                                class="upload"
                                :http-request="uploadHttp"
                                :before-upload="beforeAvatarUpload"
                                :on-remove="handleRemove"
                                :limit="1"
                                :file-list="dialogImageUrl"
                                list-type="picture-card">
                           <el-button icon="Plus" text />
                        </el-upload>
                    </el-form-item>
                </div>
                <el-dialog v-model="dialogVisible">
                    <img width="100%" :src="dialogImageUrl" alt=""/>
                </el-dialog>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="qx('ruleForm')">取 消</el-button>
                    <el-button type="primary" @click="saveEdit('ruleForm')">确 定</el-button>
                </span>
            </template>
        </el-dialog>
        <!--编辑规格-->
        <el-dialog title="修改规格" v-model="updateCareSpeVisible" width="800px">
            <el-form ref="ruleForm" :model="updateCareSpeForm" label-width="120px">
                <div style="display: flex;padding-right: 20px">
                    <el-form-item label="实际价格" prop="actualPrice">
                        <el-input class="handle-select" v-model="updateCareSpeForm.actualPrice"></el-input>
                    </el-form-item>
                    <el-form-item label="促销价格" prop="promotionPrice">
                        <el-input class="handle-select" v-model="updateCareSpeForm.promotionPrice"></el-input>
                    </el-form-item>
                </div>
                <div style="display: flex;padding-right: 20px">
                    <el-form-item label="状态" prop="state">
                        <el-select v-model="updateCareSpeForm.state " placeholder="状态" class="handle-select">
                            <el-option label="开启" value="开启"></el-option>
                            <el-option label="关闭" value="关闭"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="单人限购" prop="purchaseLimit">
                        <el-input class="handle-select" v-model="updateCareSpeForm.purchaseLimit"></el-input>
                    </el-form-item>
                </div>
                <el-form-item label="数量" prop="number">
                        <el-input type="number" class="handle-select" v-model="updateCareSpeForm.number"></el-input>
                    </el-form-item>
                <el-form-item label="商品描述" prop="specificationsName">
                    <el-input style="width: 400px" type="textarea"
                              v-model="updateCareSpeForm.specificationsName"></el-input>
                </el-form-item>
               
                <el-dialog v-model="dialogVisible">
                    <img width="100%" :src="dialogImageUrl" alt=""/>
                </el-dialog>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="qx('ruleForm')">取 消</el-button>
                    <el-button type="primary" @click="saveEdit('ruleForm')">确 定</el-button>
                </span>
            </template>
        </el-dialog>
        <!--新增规格-->
        <el-dialog title="新增规格录入" v-model="addCareSpeVisible" width="800">
            <el-form :rules="careSpeRules" ref="ruleForm" :model="addCareSpeForm" label-width="120px">
                <div style="display: flex;padding-right: 20px">
                    <el-form-item label="实际价格" prop="actualPrice">
                        <el-input type="number" class="handle-select" v-model="addCareSpeForm.actualPrice"></el-input>
                    </el-form-item>
                    <el-form-item type="number" label="促销价格" prop="promotionPrice">
                        <el-input class="handle-select" v-model="addCareSpeForm.promotionPrice"></el-input>
                    </el-form-item>
                </div>
                <div style="display: flex;padding-right: 20px">
                    <el-form-item label="状态" prop="state">
                        <el-select v-model="addCareSpeForm.state " placeholder="状态" class="handle-select">
                            <el-option label="开启" value="开启"></el-option>
                            <el-option label="关闭" value="关闭"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="单人限购" prop="purchaseLimit">
                        <el-input class="handle-select" v-model="addCareSpeForm.purchaseLimit"></el-input>
                    </el-form-item>
                </div>
                <el-form-item label="数量" prop="number">
                        <el-input type="number" class="handle-select" v-model="addCareSpeForm.number"></el-input>
                    </el-form-item>
                <el-form-item label="规格名称" prop="specificationsName">
                    <el-input style="width: 400px" type="textarea"
                              v-model="addCareSpeForm.specificationsName"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="qxAdd('ruleForm')">取 消</el-button>
                    <el-button type="primary" @click="saveCardSpeAdd('ruleForm')">确 定</el-button>
                </span>
            </template>
        </el-dialog>
        <!--新增赠送-->
        <el-dialog :title='addChatGoodsTitle' v-model="addChatSendGoodsVisible" width="800">
            <el-form :rules="sendGoodsRules" ref="ruleForm" :model="addSendGoodsForm" label-width="120px">
                <div style="display: flex;padding-right: 20px">
                    <el-form-item label="类型" prop="type">
                        <el-select v-model="addSendGoodsForm.type " placeholder="类型" class="handle-select"
                                   @change="getSendGoods">
                            <el-option label="卡片" value="1"></el-option>
                            <el-option label="器材" value="2"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="赠送" prop="typeId">
                        <el-select v-model="addSendGoodsForm.typeId" filterable placeholder="赠送"
                                   class="handle-select mr10">
                            <el-option v-for="(item,index) in cardOrEquList" :key="index" :label="item.name"
                                       :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="数量" prop="num">
                        <el-input type="number" class="handle-select" v-model="addSendGoodsForm.num"></el-input>
                    </el-form-item>
                </div>
                <div style="display: flex;padding-right: 20px">
                    <el-form-item label="价格" prop="price">
                        <el-input type="number" class="handle-select" v-model="addSendGoodsForm.price"></el-input>
                    </el-form-item>
                    <el-form-item label="状态" prop="state">
                        <el-select v-model="addSendGoodsForm.state " placeholder="状态" class="handle-select">
                            <el-option label="开启" value="开启"></el-option>
                            <el-option label="关闭" value="关闭"></el-option>
                        </el-select>
                    </el-form-item>
                </div>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="qxAdd('ruleForm')">取 消</el-button>
                    <el-button type="primary" @click="saveChatGoodsAdd('ruleForm')">确 定</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    import {edit,updateSpeState,
        updateChatSendGoodsState,
        selectCardOrEqu,
        addSendGoods,
        addCardSpec
    } from "@/api/card";
    import {cardList, editSate, del,exportExcel} from "@/api/card";
    import {selectAreaByExpress} from "@/api/area";
    import { uploadImage} from '@/api/helpManual'
    import addCardAndEditCard from "./components/addCardAndEditCard";
    // import  ossClient from '@/utils/aliyun.oss.client';
    export default {
      components:{
        addCardAndEditCard
      },
        name: "CardType",
        data() {
            return {
                updateCareSpeVisible: false,
                addCareSpeVisible: false,
                addChatSendGoodsVisible: false,
                cardOrEquList: [],
                cardSpe: {},
                updateCareSpeForm: {},
                addCareSpeForm: {},
                addSendGoodsForm: {},
                addChatGoodsTitle: null,
                cardTypeList: [],//活动卡，正价卡
                sysStudentType:localStorage.getItem('studentType'),
                query: {
                    keyword: "",
                    areaList:[],
                    pageIndex: 1,
                    pageSize: 10,

                    typeId:''
                },
                elList: [],
                regionList: [],
                campusList: [],
                campusList1: [],
                tableData: [],
                multipleSelection: [],
                deadline: null,
                delList: [],
                editVisible: false,
                addVisible: false,
                pageTotal: 0,
                form: {},
                startDateAndEndDate: [],

                idx: -1,
                id: -1,
                dialogImageUrl: [],
                imgList: [],
                rules: {//卡片
                    cardname: [
                        {required: true, message: '请输入班卡名称', trigger: 'blur'},
                    ],
                    eliminatetype: [
                        {required: true, message: '请选择消课方式', trigger: 'change'},
                    ],
                    role: [
                        {required: true, message: '请选择可见权限', trigger: 'change'},
                    ],
                    regionid: [
                        {required: true, message: '请选择所属城市', trigger: 'change'}
                    ],
                    campusid: [
                        {required: true, message: '请选择所属校区', trigger: 'change'}
                    ],
                    describe: [
                        {required: true, message: '请输入商品描述', trigger: 'blur'},
                    ],
                    explain: [
                        {required: true, message: '请输入收据', trigger: 'blur'},
                    ],
                    price: [
                        {required: true, message: '请输入价格', trigger: 'blur'},
                    ],
                    type: [
                        {required: true, message: '请卡片类型', trigger: 'blur'},
                    ],
                },
                careSpeRules: {//规格
                    actualPrice: [
                        {required: true, message: '请输入实际价格', trigger: 'blur'},
                    ],
                    promotionPrice: [
                        {required: true, message: '请输入促销价格', trigger: 'blur'},
                    ],
                    state: [
                        {required: true, message: '请状态', trigger: 'blur'},
                    ],
                    purchaseLimit: [
                        {required: true, message: '请输入单人购买限制', trigger: 'blur'},
                    ],
                    specificationsName: [
                        {required: true, message: '请输入规格名称', trigger: 'blur'},
                    ],
                },
                sendGoodsRules: {//赠送
                    type: [
                        {required: true, message: '请选择赠送类型', trigger: 'blur'},
                    ],
                    typeId: [
                        {required: true, message: '请选择赠送', trigger: 'blur'},
                    ],
                    num: [
                        {required: true, message: '请输入数量', trigger: 'blur'},
                    ],
                    price: [
                        {required: true, message: '请输入价格', trigger: 'blur'},
                    ],
                    state: [
                        {required: true, message: '请选择状态', trigger: 'blur'},
                    ],
                },
                uploadConf: {
                    region: 'oss-cn-beijing',
                    accessKeyId: 'LTAI4G88H5Fc5mEjzXaQipfW',
                    accessKeySecret: '0Z4k2fYL96OSVjyHM8BQTKoNZyvmDa',
                    bucket: 'yanhuosports',
                },
            }
                ;
        },
        created() {
          selectAreaByExpress().then(res => {
            if (res.code == 200) {
              this.regionList = res.data
            } else {
              this.$message.error("获取城市数据失败");
            }
          })
            this.getData();
            // 城市下拉数据
            // 卡种类型下拉


        },
        methods: {
          updateDialog(val){
            this.addVisible = val;
          },
          getAreaListId(val){
            this.query.areaList = val;
          },
            // 获取数据
            getData() {
                // 表格数据
                this.tableLoading = true
                cardList(this.query).then(res => {
                    this.tableLoading = false
                    if (res.code == 200) {
                        this.tableData = res.data.records;
                        this.pageTotal = res.data.total;
                    } else {
                        this.$message.error("获取表格数据失败");
                    }
                }).catch(() => {
                })
            },
            //规格状态
            setSpeState(id, state) {
                updateSpeState(id, state).then(res => {
                    if (res.code === 200) {
                        this.$message.success(`操作成功`);
                    } else {
                        this.$message.error(`操作失败`);
                    }
                })
            },
            addCareSpe(index, row) {
                console
                this.addCareSpeVisible = true;
                this.addCareSpeForm.cardId = row.id;
            },
            //新增赠送
            addChatSendGoods(index, row) {
                this.addChatSendGoodsVisible = true;
                this.cardSpe = row;
                this.addChatGoodsTitle = row.specificationsName + "赠送录入";
                this.addSendGoodsForm.cardSpeId = row.id;
            },
            //根据
            getSendGoods(id) {
                console.log(this.cardSpe);
                selectCardOrEqu(this.cardSpe.cardId, id).then(res => {
                    if (res.code === 200) {
                        this.cardOrEquList = res.data;
                    } else {
                        this.$message.error(`操作失败`);
                    }
                })
                console.log(id);
            },
            //赠送状态
            setChatSendGoodsState(id, state) {
                updateChatSendGoodsState(id, state).then(res => {
                    if (res.code === 200) {
                        this.getData();
                        this.$message.success(`操作成功`);
                    } else {
                        this.$message.error(`操作失败`);
                        this.getData();
                    }
                })
            },
            //编辑规格
            updateCardSpe(index, val) {
                this.updateCareSpeVisible = true;
                console.log(index);
                console.log(val);
                this.updateCareSpeForm = val;
            },
            // 触发搜索按钮
            handleSearch() {
                this.query.pageIndex = 1
                this.getData();
            },
            // 删除操作
            handleDelete(index, row) {
                // 二次确认删除
                this.$confirm("确定要删除卡种: " + row.cardname + " 吗?", "提示", {
                    type: "warning"
                }).then(() => {
                    del(row.id).then(res => {
                        if (res.code == 200) {
                            this.$message.success("删除成功");
                            this.tableData.splice(index, 1);
                            this.pageTotal = this.pageTotal - 1;
                        } else {
                            this.$message.error("删除失败");
                        }
                    })

                }).catch(() => {
                });
            },
            // 多选操作
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            delAllSelection() {
                const length = this.multipleSelection.length;
                let str = "";
                this.delList = this.delList.concat(this.multipleSelection);
                for (let i = 0; i < length; i++) {
                    str += this.multipleSelection[i].name + " ";
                }
                this.$message.error(`删除了${str}`);
                this.multipleSelection = [];
            },
            // 编辑操作
            handleEdit(index, row) {
                this.dialogImageUrl = [];
                this.idx = index;
                this.form = row;
                this.editVisible = true;
                let obj = new Object();
                console.log(row.thumbnail);
                if (row.thumbnail != null) {
                    obj.url = row.thumbnail;
                    this.dialogImageUrl.push(obj);
                }
            },
            //取消编辑
            qx(formName) {
                this.editVisible = false;
                this.$refs[formName].resetFields()
                this.startDateAndEndDate = []
                this.$message.info(`您取消了修改`);
            },
            // 保存编辑
            async saveEdit(formName) {
                this.$refs[formName].validate(async () => {
                    if (this.form.secKillTime != null) {
                        this.form.secKillTime = this.dateOfReturnFormatter(this.form.secKillTime)
                    }
                    if (this.form.soldEndTime != null) {
                        this.form.soldEndTime = this.dateOfReturnFormatter(this.form.soldEndTime)
                    }
                    if (this.form.startdate != null) {
                        this.form.startdate = this.dateOfReturnFormatter(this.form.startdate)
                    }
                    if (this.form.enddate != null) {
                        this.form.enddate = this.dateOfReturnFormatter(this.form.enddate)
                    }
                    // if (this.activityImg != '') {
                    //     await this.insertOSS("edit");
                    // }
                    edit(JSON.parse(JSON.stringify(this.form))).then(async res => {
                        this.editVisible = false;
                        if (res.code === 200) {
                            this.$message.success(`修改成功`);
                            this.getData();
                        } else {
                            this.$message.error(`修改失败`);
                        }
                    })
                });
            },
            qxAdd() {
                this.addVisible = false
                this.startDateAndEndDate = []
            },

            //新增规格
            async saveCardSpeAdd(formName) {
                this.$refs[formName].validate(async (valid) => {
                    if (valid) {
                        addCardSpec(this.addCareSpeForm).then(res => {
                            if (res.code === 200) {
                                this.getData();
                                this.addCareSpeVisible = false;
                                this.$message.success(`新增成功`);
                            } else {
                                this.$message.error(`新增失败`);
                            }
                        })
                    } else {
                        return false;
                    }
                });
            },
            //新增赠送
            async saveChatGoodsAdd(formName) {
                this.$refs[formName].validate(async (valid) => {
                    if (valid) {
                        addSendGoods(this.addSendGoodsForm).then(res => {
                            this.addChatSendGoodsVisible = false;
                            if (res.code === 200) {
                                this.getData();
                                this.addSendGoodsForm = {};
                                this.$message.success(`新增成功`);
                            } else {
                                this.$message.error(`新增失败`);
                            }
                        })
                    } else {
                        return false;
                    }
                });
            },
            // 分页导航
            handlePageChange(val) {
                this.query.pageIndex = val
                this.getData();
            },
            // 选中城市  渲染校区
            selectCity1(val) {
                this.query.campusId = null
                this.campusList = JSON.parse(JSON.stringify(this.regionList[val])).campuses;
            },

            // 编辑 选中城市  渲染校区
            selectCity2(val) {
                this.campusList1 = JSON.parse(JSON.stringify(this.regionList[val])).campuses;
                this.form.campusid = null
                this.addForm.campusid = null
            },
            // 修改教练状态
            setState(id, state) {
                editSate(id, state).then(res => {
                    if (res.code == 200) {
                        this.$message.success(`操作成功`);
                    } else {
                        this.$message.error(`操作失败`);
                    }
                })
            },
            exportExcel() {
                // 二次确认删除
                this.loading = true
                exportExcel(this.query).then(res => {
                    this.loading = false
                    let blob = new Blob([res], {
                        type: "application/vnd.ms-excel"
                    });
                    console.log(blob);
                    let objectUrl = URL.createObjectURL(blob);
                    console.log(objectUrl);
                    const link = document.createElement('a'); //我们用模拟q标签点击事件
                    link.href = objectUrl;
                    link.setAttribute('download', "卡片");
                    document.body.appendChild(link);
                    /**
                     * 在这期间，我们可以做自己界面的反应逻辑
                     **/
                    link.click(); //点击
                    document.body.removeChild(link); // 下载完成移除元素
                    window.URL.revokeObjectURL(URL); // 释放掉blob对象
                })
            },
            /**
             * 上传阿里云OSS
             */
            // async insertOSS(val) {
            //     console.log(val);
            //     console.log(this.form.thumbnail);
            //     return await new Promise((resolve, reject) => {
            //         const fileName = 'CardType/' + this.getCurrentTime() + ".png";  //定义唯一的文件名
            //         ossClient(this.uploadConf).put(fileName, this.activityImg, {'ContentType': 'image/png'}).then(({res, name}) => {
            //             if (res && res.status == 200) {
            //                 if (val === "add") {
            //                     this.addForm.thumbnail = 'https://codeimg.yanhuotiyu.com/' + name;
            //                 } else {
            //                     console.log('https://codeimg.yanhuotiyu.com/' + name)
            //                     this.form.thumbnail = 'https://codeimg.yanhuotiyu.com/' + name;
            //                 }
            //                 console.log("上传图片成功")
            //                 resolve(name);
            //             }
            //         }).catch(() => {
            //             this.$message.error('上传图片失败！');
            //             reject();
            //         });
            //     });
            // },
            /**
             * 删除图片
             */
            handleRemove() {
                this.activityImg = '';
            },
            /**
             * 修改卡种
             * 保存图片
             */
            uploadHttp({file}) {     
                this.activityImg = file;
                const data = new FormData()
                data.append(
                'multipartFile',file
                )
                data.append(
                'flag','CardType/'
                )
                data.append(
                'fileName',file.name
                )
                uploadImage(data).then(res=>{ 
                // console.log(res);
                this.form.thumbnail= res.data
                })
            },
            /**
             * 图片限制
             */
            beforeAvatarUpload(file) {
                const isJPG = file.name.split('.')[1] === 'jpg';
                const isPNG = file.name.split('.')[1] === 'png';
                const one = file.limit === 1;
                if (!isJPG && !isPNG) {
                    this.$message.error('上传图片只能是 JPG/PNG 格式!');
                }
                if (one > 2) {
                    this.$message.error('上传图片只能1张!');
                }
                return (isJPG || isPNG);
            },
            // 重置
            reset() {
                this.query.regionId = ""
                this.query.campusId = ""
                this.query.type = ""
                this.query.keyword = ""
                this.query.pageIndex = 1
                this.query.typeId=''
                this.getData()
            },
            dateFormat(val) {
                var date = new Date(val)
                var y = date.getFullYear()
                var m = date.getMonth() + 1
                m = m < 10 ? ('0' + m) : m
                var d = date.getDate()
                d = d < 10 ? ('0' + d) : d
                const time = parseInt(y) + '-' + parseInt(m) + '-' + parseInt(d);
                console.log(time);
                return time
            },
            dateOfReturnFormatter(time) {
                //如果数据不存在设置为'',否则为:1970-01-1 8:0:0
                if (!time) {
                    return;
                }
                var date = new Date(time);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
                let Y = date.getFullYear() + '-';
                let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
                let D = date.getDate() + ' ';
                D = D <= 10 ? '0' + D : D;
                let h = date.getHours() + ':';
                h = h.length == 2 ? '0' + h : h;
                let m = date.getMinutes() + ':';
                m = m.length == 2 ? '0' + m : m;
                let s = date.getSeconds();
                s = s <= 10 ? '0' + s : s;
                return Y + M + D + h + m + s;
            },
            /**
             * 获取时间
             */
            getCurrentTime() {
                //获取当前时间并打印
                let _this = this;
                let yy = new Date().getFullYear();
                let mm = new Date().getMonth() + 1;
                let dd = new Date().getDate();
                let hh = new Date().getHours();
                let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
                let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
                return _this.gettime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss;
            },
        },
        watch: {
            deadline() {
                if (this.deadline != null) {
                    this.addForm.secKillTime = this.dateOfReturnFormatter(this.deadline[0]);
                    this.addForm.soldEndTime = this.dateOfReturnFormatter(this.deadline[1]);
                } else {
                    this.addForm.seckillTime = null;
                    this.addForm.soldEndTime = null;
                }

            }
        }

    };
</script>

<style scoped>
    .handle-box {
        margin-bottom: 20px;
    }

    .handle-select {
        width: 180px;
    }


    .table {
        width: 100%;
        font-size: 14px;
    }

    .red {
        color: #ff0000;
    }

    .handle-input {
        width: 300px;
        display: inline-block;
    }

    .mr10 {
        margin-right: 10px;
    }

    .table-td-thumb {
        display: block;
        margin: auto;
        width: 40px;
        height: 40px;
    }

    .hello >>> .hide .el-upload--picture-card {
        display: none;
    }
    .code {
      margin-top: 6px;
      width: 40px;
      height: 40px;
      border-radius: 8px;
      background-color: #efefef;
    }
</style>
