<template>
  <!-- 新增弹出框框 -->
  <el-dialog title="卡种信息录入" v-model="addDialog" width="1050px">
    <el-form :rules="rules" ref="ruleForm" :model="addForm" label-width="120px">
      <div style="padding-right: 40px">
        <el-row>
          <el-col :span="8">
            <el-form-item prop="cardname" label="卡种名称">
              <el-input class="handle-select" v-model="addForm.cardname"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="regionid" label="所属区域">
              <el-select v-model="addForm.regionid" placeholder="请选择">
                <el-option v-for="(item,index) in regionList" :key="index"
                           :label="item.name"
                           :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="所属校区">
              <el-select v-model="addForm.campusid" placeholder="请选择" disabled>
                <el-option v-for="(item,index) in campusList1" :key="index" :label="item.campusname"
                           :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item prop="eliminatetype" label="消课方式">
              <el-select v-model="addForm.eliminatetype" placeholder="消课方式">
                <el-option v-for="(item,index) in eliminateType" :key="index" :label="item.name"
                           :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="有效日期">
              <el-input placeholder="有效天数" v-model="addForm.effectiveTime"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="可见权限" prop="role">
              <el-select v-model="addForm.role " placeholder="谁可以看">
                <el-option label="全部可见" value="0"></el-option>
                <el-option label="教练可见" value="1"></el-option>
                <el-option label="家长可见" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="课单价" prop="price">
              <el-input type="number" v-model="addForm.price"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="卡片类型" prop="type">
              <el-select v-model="addForm.type" placeholder="请选择">
                <el-option v-for="(item,index) in cardTypeList" :key="index"
                           :label="item.dictLabel" :value="item.dictValue"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="卡片类型" prop="flag">
              <el-select placeholder="卡片类型" v-model="addForm.flag">
                <div v-if="sysStudentType==3">
                  <el-option label="中招体育" value="1"></el-option>
                  <el-option label="少年" value="2"></el-option>
                  <el-option label="高考体育" value="4"></el-option>
                </div>
                <div v-if="sysStudentType!=3">
                  <el-option v-if="sysStudentType==1" label="中招体育" value="1"></el-option>
                  <el-option v-else label="少年" value="2"></el-option>
                  <el-option label="高考体育" value="4"></el-option>
                </div>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div style="display: flex;padding-right: 40px">
        <el-form-item label="固定日期">
          <el-date-picker
              style="width: 100%"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              v-model="startDateAndEndDate"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="抢购日期">
          <el-date-picker
              style="width: 100%"
              v-model="robPurchase"
              type="datetimerange"
              range-separator="至"
              start-placeholder="上架日期"
              end-placeholder="下架日期"
          />
        </el-form-item>
      </div>
      <div style="display: flex;padding-right: 40px">
        <div>
          <el-form-item label="收据备注" prop="explain">
            <el-input type="textarea" v-model="addForm.explain" style="width: 365px" :rows="2"
                      placeholder="如：送保险100元，正价次卡100元/次，赠送次数不退不换不转"></el-input>
          </el-form-item>
          <el-form-item label="商品描述" prop="describe">
            <el-input :rows="3"  style="width: 365px" type="textarea"
                      v-model="addForm.describe"></el-input>
          </el-form-item>
        </div>
        <div style="display: flex">
          <el-form-item label="缩略图" prop="thumbnail">
            <el-upload
                class="upload"
                :http-request="uploadHttp"
                :before-upload="beforeAvatarUpload"
                :on-remove="handleRemove"
                :class="{disUoloadSty:activityImg!=null ? true:false}"
                :limit="1"
                list-type="picture-card">
             <el-button icon="Plus" text />
            </el-upload>
          </el-form-item>
          <el-form-item label="排序" prop="sort" style="margin-left:1px">
            <el-input style="width: 140px" type="number" v-model="addForm.sort"></el-input>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button>取 消</el-button>
        <el-button type="primary" @click="saveAdd('ruleForm')">确 定</el-button>
      </span>
    </template>
  </el-dialog>

</template>

<script>
import {selectAreaByExpress} from "@/api/area";
import {elList,
  add
} from "@/api/card";
import {uploadImage}from '@/api/helpManual'
// import ossClient from '@/utils/aliyun.oss.client';
import {selectDictByType} from "@/api/dict";

export default {
  name: "addCardAndEditCard",
  props:{
    addVisible: String,
  },
  data() {
    return {
      activityImg:null,
      sysStudentType:localStorage.getItem('studentType'),
      addForm: {},
      addDialog:false,
      startDateAndEndDate:[],
      robPurchase:[],
      rules: {//卡片
        cardname: [
          {required: true, message: '请输入班卡名称', trigger: 'blur'},
        ],
        eliminatetype: [
          {required: true, message: '请选择消课方式', trigger: 'change'},
        ],
        role: [
          {required: true, message: '请选择可见权限', trigger: 'change'},
        ],
        regionid: [
          {required: true, message: '请选择所属城市', trigger: 'change'}
        ],
        describe: [
          {required: true, message: '请输入商品描述', trigger: 'blur'},
        ],
        explain: [
          {required: true, message: '请输入收据', trigger: 'blur'},
        ],
        price: [
          {required: true, message: '请输入价格', trigger: 'blur'},
        ],
        type: [
          {required: true, message: '请卡片类型', trigger: 'blur'},
        ],
        flag: [
          {required: true, message: '请选择类型', trigger: 'blur'},
        ],
      },
      regionList:[],
      eliminateType:[]
    }
  },
  methods:{
    /**
     * 新增
     */
    saveAdd(formName) {
      console.log(this.robPurchase);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.startDateAndEndDate.length > 0) {
            this.addForm.startdate = this.dateFormat(this.startDateAndEndDate[0])
            this.addForm.enddate = this.dateFormat(this.startDateAndEndDate[1])
          }
          if (this.robPurchase.length>0) {
            this.addForm.secKillTime = this.timestampToTime2(this.robPurchase[0])
            this.addForm.soldEndTime = this.timestampToTime2(this.robPurchase[1])
            console.log(this.addForm.secKillTime);
            console.log(  this.addForm.soldEndTime );
           
          }
          this.startDateAndEndDate = []
          add(this.addForm).then(res => {
            if (res.code == 200) {
              if (res.data > 0) {
                this.addDialog = false;          
                this.$message.success(`新增卡种: ${this.addForm.cardname} 成功`);
                this.addForm = {};
              } else {
                this.$message.error(`新增卡种: ${this.addForm.cardname} 失败`);
              }
            } else {
              this.$message.error(`新增卡种: ${this.addForm.cardname} 失败`);
            }
            this.addForm.startdate = null
            this.addForm.enddate = null
            this.$refs[formName].resetFields();
            this.activityImg = '';
          })
        
       } 
        else {
          return false;
        }
      });
    },
    /**
     * 删除图片
     */
    handleRemove() {
      this.activityImg = null;
    },
    dateFormat(val) {
      var date = new Date(val)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? ('0' + m) : m
      var d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      const time = y + '-' + m + '-' + d
      return time
    },
    timestampToTime2(timestamp) {
  if (timestamp === 0 || timestamp == null) {
    return ''
  } else {
    var date = new Date(timestamp)
    var Y = date.getFullYear() + '-'
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
    var D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' '
    var H = date.getHours() + ':'
    var M2 = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
    var S = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    return Y + M + D + H + M2 + S
  }
},
    /**
     * 保存图片
     */
   async uploadHttp({file}) {
      this.activityImg = file;
      const data = new FormData()
      data.append(
       'multipartFile',file
       )
       data.append(
      'flag','CardType/'
    )
      data.append(
      'fileName',file.name
     )
    this.addForm.thumbnail = await uploadImage(data)
    },
    /**
     * 图片限制
     */
    beforeAvatarUpload(file) {
      const isJPG = file.name.split('.')[1] === 'jpg';
      const isPNG = file.name.split('.')[1] === 'png';
      const one = file.limit === 1;
      if (!isJPG && !isPNG) {
        this.$message.error('上传图片只能是 JPG/PNG 格式!');
      }
      if (one > 2) {
        this.$message.error('上传图片只能1张!');
      }
      return (isJPG || isPNG);
    },
    /**
     * 获取时间
     */
    getCurrentTime() {
      //获取当前时间并打印
      let _this = this;
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
      let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
      return _this.gettime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss;
    },
  },
  created() {
    /**
     * 查询区域
     */
    selectAreaByExpress().then(res => {
      if (res.code == 200) {
        this.regionList = res.data
      } else {
        this.$message.error("获取城市数据失败");
      }
    })
    /**
     * 查询卡片类型  1.活动卡 2.正价卡
     */
    selectDictByType("cardType").then(res => {
      if (res.code == 200) {
        this.cardTypeList = res.data;
      } else {
        this.$message.error("获取数据失败");
      }
    });
    /**
     * 查询消课方式  1.活动卡 2.正价卡
     */
    elList().then(res => {
      if (res.code == 200) {
        this.eliminateType = res.data
      } else {
        this.$message.error("获取卡种类型数据失败");
      }
    });

  },
  watch:{
    addVisible(newV,ol) {
      console.log(newV,ol)
      this.addDialog = this.addVisible;
    },
    addDialog(newV) {
      if (newV == false || newV==undefined){
        this.$emit('updateDialog',false);
      }
    },
  }
}
</script>

<style scoped>
.disUoloadSty /deep/ .el-upload--picture-card{
  display:none;   /* 上传按钮隐藏 */
}

/deep/ .el-upload--picture-card{
  width: 80px;
  height: 80px;
}
/deep/ .el-upload{
  width: 80px;
  height: 80px;
  line-height: 80px;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item{
  width: 80px;
  height: 80px;
  line-height: 80px;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail{
  width: 80px;
  height: 80px;
  line-height: 80px;
}
/deep/ .avatar{
  width: 80px;
  height: 80px;
}
</style>